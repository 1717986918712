import React from 'react';

import { ButtonBlock } from '@dop/ui-primitives/interaction/InteractionBlock';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import {
	TextBlock,
	TextBlockProps,
} from '@dop/ui-primitives/typography/TextBlock';
import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { IconPlusThin } from '@dop/ui-icons/svg/plusThin';
import { Collapse } from '@dop/shared/components/collapse/collapse';
import { addTestSelector } from '@dop/shared/helpers/testSelector';

import { useAccordion } from './useAccordion';
import { Item } from './Accordion.types';

export const AccordionItem = ({
	item,
	asHeading = 'h3',
}: {
	item: Item;
	asHeading?: TextBlockProps['as'];
}): React.ReactElement => {
	const { expandedMap, toggleExpandedForItem } = useAccordion();
	const htmlId = React.useId();

	return (
		<Box $borderBlockStart={['1px', 'solid', ['positive', 'normal']]}>
			<Box as={asHeading}>
				<ButtonBlock
					type="button"
					$inlineSize="100%"
					onClick={() => toggleExpandedForItem(item.id)}
					aria-expanded={expandedMap[item.id]}
					aria-controls={`accordion-item-content-${htmlId}`}
					id={`accordion-item-title-${htmlId}`}
					{...addTestSelector(`uiAccordionItem-${item.header}`)}
				>
					<Box $padding={[1.5, 0]}>
						<Shelf
							$gap={2}
							$justifyContent="space-between"
							$alignItems="center"
						>
							<TextBlock
								$hover={{ $textDecorationLine: 'underline' }}
								$color={['text', 'secondary']}
								$fontWeight={expandedMap[item.id] ? 'bold' : 'normal'}
							>
								{item.header}
							</TextBlock>
							<Svg
								$blockSize={1.5}
								$inlineSize="auto"
								$fill={['positive', 'normal']}
								$rotate={expandedMap[item.id] ? '135deg' : '0deg'}
								$transition="rotate 500ms"
								as={IconPlusThin}
							/>
						</Shelf>
					</Box>
				</ButtonBlock>
			</Box>
			<Collapse
				id={`accordion-item-content-${htmlId}`}
				aria-labelledby={`accordion-item-title-${htmlId}`}
				role="region"
				isOpened={expandedMap[item.id]}
				{...addTestSelector(`uiAccordionContent-${item.header}`)}
			>
				<Box $padding={[1, 0, 2]}>{item.content}</Box>
			</Collapse>
		</Box>
	);
};
