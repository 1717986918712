import { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { FlattenSimpleInterpolationMaybe } from '../base/StyleProps.types';
import { Gap } from './layoutDefinitions';
import { ExcludeFlexPositionValues } from '../base/baseDefinitions';

const getGapCSSValue = (gap: Gap) => {
	if (Array.isArray(gap)) {
		const [rowGap, columnGap] = gap;

		return `calc(var(--root-cap) * ${rowGap}) calc(var(--root-cap) * ${columnGap})`;
	}

	return `calc(var(--root-cap) * ${gap})`;
};
export const getGapCSS = (gap?: Gap): FlattenSimpleInterpolationMaybe => {
	return (
		gap != null &&
		css`
			gap: ${getGapCSSValue(gap)};
		`
	);
};

export const getColumnsGapCSS = (
	gap?: Gap
): FlattenSimpleInterpolationMaybe => {
	if (gap == null) {
		return css`
			--columns-row-gap: unset;
		`;
	}
	const [rowGap, columnGap] = Array.isArray(gap) ? gap : [gap, gap];
	return css`
		--columns-row-gap: calc(${rowGap} * var(--root-cap));
		column-gap: calc(${columnGap} * var(--root-cap));
	`;
};
export const getAlignItemCSS = (
	alignItems?: ExcludeFlexPositionValues<CSSProperty.AlignItems>
): FlattenSimpleInterpolationMaybe =>
	css`
		align-items: ${alignItems};
	`;
export const getJustifyItemsCSS = (
	justifyItems?: ExcludeFlexPositionValues<CSSProperty.JustifyItems>
): FlattenSimpleInterpolationMaybe =>
	css`
		justify-items: ${justifyItems};
	`;
export const getAlignContentCSS = (
	alignContent?: ExcludeFlexPositionValues<CSSProperty.AlignContent>
): FlattenSimpleInterpolationMaybe =>
	css`
		align-content: ${alignContent};
	`;
export const getJustifyContentCSS = (
	justifyContent?: ExcludeFlexPositionValues<CSSProperty.JustifyContent>
): FlattenSimpleInterpolationMaybe =>
	justifyContent != null &&
	css`
		justify-content: ${justifyContent};
	`;
export const getGridTemplateColumnsCSS = (
	columns?: CSSProperty.GridTemplateColumns
): FlattenSimpleInterpolationMaybe =>
	columns != null &&
	css`
		grid-template-columns: ${columns};
	`;

export const getGridTemplateRowsCSS = (
	rows?: CSSProperty.GridTemplateRows
): FlattenSimpleInterpolationMaybe =>
	rows != null &&
	css`
		grid-template-rows: ${rows};
	`;
export const getGridAutoColumnsCSS = (
	autoColumns?: CSSProperty.GridAutoColumns
): FlattenSimpleInterpolationMaybe =>
	autoColumns != null &&
	css`
		grid-auto-columns: ${autoColumns};
	`;

export const getGridAutoRowsCSS = (
	autoRows?: CSSProperty.GridAutoRows
): FlattenSimpleInterpolationMaybe =>
	autoRows != null &&
	css`
		grid-auto-rows: ${autoRows};
	`;

export const getGridAutoFlowCSS = (
	autoFlow?: CSSProperty.GridAutoFlow
): FlattenSimpleInterpolationMaybe =>
	autoFlow != null &&
	css`
		grid-auto-flow: ${autoFlow};
	`;

export const getGridTemplateAreasCSS = (
	areas?: CSSProperty.GridTemplateAreas
): FlattenSimpleInterpolationMaybe =>
	areas != null &&
	css`
		grid-template-areas: ${areas};
	`;

export const getGridTemplateRowAreasCSS = (
	areas?: Array<string>
): FlattenSimpleInterpolationMaybe =>
	areas != null &&
	css`
		grid-template-areas: '${areas.join(' ')}';
	`;

export const getGridTemplateColumnAreasCSS = (
	areas?: Array<string>
): FlattenSimpleInterpolationMaybe =>
	areas != null &&
	css`
		grid-template-areas: ${areas.map((area) => `'${area}'`).join('\n')};
	`;
