import React from 'react';

import {
	ABlock,
	ABlockProps,
} from '@dop/ui-primitives/interaction/InteractionBlock';
import { isProtocolWhitelisted } from '@dop/shared/helpers/link';
import { DOPLink } from '@/uiComposites/interactive/DOPLink';

const LinkBlock_ = (
	{
		linkType,
		...props
	}: Omit<ABlockProps, 'data-link-type'> & { linkType: string },
	ref: React.ForwardedRef<HTMLAnchorElement>
): React.ReactElement | null => {
	if (props.href == null) return null;

	if (!isProtocolWhitelisted(props.href)) {
		return null;
	}

	return (
		<DOPLink
			href={props.href}
			data-link-type={linkType}
			legacyBehavior
			passHref
		>
			<ABlock ref={ref} data-link-type={linkType} {...props} />
		</DOPLink>
	);
};

/**
 * If you need to use a styled link block, you probably want to use the upcoming ContentLinkBlock (DOP-7913). Use this component if you need an unstyled link.
 */
export const LinkBlock = React.forwardRef(LinkBlock_);
export type LinkBlockProps = React.ComponentPropsWithRef<typeof LinkBlock>;
