import React from 'react';

import { translate } from '@dop/shared/translate/translate';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import { ButtonBlock } from '@dop/ui-primitives/interaction/InteractionBlock';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import { addTestSelector } from '@dop/shared/helpers/testSelector';

import { useAccordion } from './useAccordion';

export const AccordionToggleAll = () => {
	const { expandedMap, setExpandedForAllItems } = useAccordion();

	const allExpanded = React.useMemo(
		() => Object.values(expandedMap).every((isExpanded) => isExpanded === true),
		[expandedMap]
	);
	const allCollapsed = React.useMemo(
		() =>
			Object.values(expandedMap).every((isExpanded) => isExpanded === false),
		[expandedMap]
	);

	return (
		<Box>
			<Shelf $gap={1} $justifyContent="end">
				<ButtonBlock
					onClick={() => setExpandedForAllItems(false)}
					disabled={allCollapsed}
					{...addTestSelector('uiCloseAllAccordionsButton')}
				>
					<TextBlock
						$hover={{ $textDecorationLine: 'underline' }}
						$color={allCollapsed ? ['text', 'muted'] : ['text', 'secondary']}
					>
						{translate('close all', { transform: 'lowercase' })}
					</TextBlock>
				</ButtonBlock>
				<ButtonBlock
					onClick={() => setExpandedForAllItems(true)}
					disabled={allExpanded}
					{...addTestSelector('uiOpenAllAccordionsButton')}
				>
					<TextBlock
						$hover={{ $textDecorationLine: 'underline' }}
						$color={allExpanded ? ['text', 'muted'] : ['text', 'secondary']}
					>
						{translate('open all', { transform: 'lowercase' })}
					</TextBlock>
				</ButtonBlock>
			</Shelf>
		</Box>
	);
};
