import React from 'react';

import { Grid } from '@dop/ui-primitives/layout/Grid';

import { columnSizes } from './pageLayout.styles';
import { SlotElement } from './pageLayout.types';

export type Props = {
	mainSlot: SlotElement;
};

/**
 * Default layout grid for a page with correct side-padding (for a layout with 2 columns on desktop, use twoColumnRowLayout)
 */
export const MainRowLayout = ({
	mainSlot,
}: Props): React.ReactElement | null => {
	return (
		<Grid
			$columns={columnSizes.oneColumn.join(' ')}
			$areas={`
				${mainSlot ? '"side-space-start main side-space-end"' : ''}
			`}
		>
			{mainSlot && React.cloneElement(mainSlot, { $gridArea: 'main' })}
		</Grid>
	);
};
