import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import { getNormalBackgroundColorCSS } from '../planeDivision/planeDivisionStyleFunctions';
import { ShapeColor } from '../colorTheme/ColorTheme.types';

export type ImagePrimitiveStaticStyleProps = BaseStaticStyleProps & {
	$objectFit?: CSSProperty.ObjectFit;
	$objectPosition?: CSSProperty.ObjectPosition;
	$backgroundColor?: ShapeColor;
};

export type ImagePrimitiveComponentStyleProps =
	ComponentStyleProps<ImagePrimitiveStaticStyleProps>;

export const imagePrimitiveStaticStyleMap: StyleMap<ImagePrimitiveStaticStyleProps> =
	{
		...baseStaticStyleMap,
		$objectFit: (objectFit = 'contain') =>
			objectFit != null &&
			css`
				object-fit: ${objectFit};
			`,
		$objectPosition: (objectPosition) =>
			objectPosition != null &&
			css`
				object-position: ${objectPosition};
			`,
		$backgroundColor: getNormalBackgroundColorCSS,
	};

const imagePrimitiveStyleMap: ComponentStyleMap<ImagePrimitiveStaticStyleProps> =
	{
		normal: imagePrimitiveStaticStyleMap,
	};

/**
 * A component for responsive images.
 *
 * In most cases this componet should not be used directly. Use `<Image>` from ui-composites instead.
 *
 * Responsibilities:
 *
 * 1. Allow [responsive images](https://css-tricks.com/a-guide-to-the-responsive-images-syntax-in-html/) with `sizes` and `srcSet` props.
 * 2. Allow lazy loading with `loading="lazy"` prop. This prop is required to make this choice explicit every time.
 * 3. Object fit and position.
 * 4. A background color can be set to have a placeholder during image loading.
 */
export const ImagePrimitive = styled.img<
	ImagePrimitiveComponentStyleProps &
		Required<
			Pick<React.ImgHTMLAttributes<HTMLImageElement>, 'alt' | 'src' | 'loading'>
		>
>`
	${(props) => {
		return css`
			display: block;
			${baseStyle};

			${getCSSFromStyleProps(imagePrimitiveStyleMap, props)};
		`;
	}};
`;

export type ImagePrimitiveProps = StyledComponentPropsWithoutRef<
	typeof ImagePrimitive
>;
