import React from 'react';
import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import {
	getDisplayCSS,
	getVerticalAlignCSS,
} from '../base/commonStyleFunctions';
import { getNormalFill, getInteractiveFill } from './mediaStyleFunctions';
import { Fill } from './mediaDefinitions';

/**
 * Props for component that renders the actual SVG to be displayed.
 */
export type SvgContentProps = JSX.IntrinsicElements['svg'] & {
	cropped?: boolean;
};
/**
 * Component that renders the actual SVG to be displayed.
 */
export type SvgContent = (
	svgContentProps: SvgContentProps
) => React.ReactElement | null;

export type SvgStaticStyleProps = Omit<BaseStaticStyleProps, '$display'> & {
	$display?: Extract<
		CSSProperty.Display,
		CSSProperty.All | 'none' | 'inline-block'
	>;
	$verticalAlign?: CSSProperty.VerticalAlign;
	$overflow?: CSSProperty.Overflow;
};

export type SvgInteractiveStyleProps = {
	/**
	 * If a normal color is provided the CSS fill prop will be set
	 *
	 * If an object is set the keys and values will be converted to
	 * CSS custom property as following:
	 *
	 *  `--fill-${key}: ${value};`
	 *
	 * This is useful when multiple shapes in the CSS different colors
	 *
	 * In the SVG this can be used as following:
	 *
	 *  <path fill="var(--fill-key, <default color>)" />
	 */
	$fill?: Fill;
};

type RequireProp<
	Obj extends Record<string, unknown>,
	Keys extends keyof Obj
> = Omit<Obj, Keys> & Required<Pick<Obj, Keys>>;

export type SvgComponentStyleProps = RequireProp<
	ComponentStyleProps<
		SvgStaticStyleProps,
		SvgInteractiveStyleProps,
		SvgContent
	>,
	'as'
> &
	SvgContentProps;

export const svgStaticStyleMap: StyleMap<
	SvgStaticStyleProps & SvgInteractiveStyleProps
> = {
	...baseStaticStyleMap,
	$display: getDisplayCSS,
	$verticalAlign: getVerticalAlignCSS,
	$inlineSize: (inlineSize = 'auto') =>
		baseStaticStyleMap.$inlineSize(inlineSize),
	$blockSize: (blockSize = 'auto') => baseStaticStyleMap.$blockSize(blockSize),
	$fill: getNormalFill,
	$overflow: (overflow = 'visible') => baseStaticStyleMap.$overflow(overflow),
};

export const svgInteractiveStyleMap: StyleMap<SvgInteractiveStyleProps> = {
	$fill: getNormalFill,
};

const svgStyleMap: ComponentStyleMap<
	SvgStaticStyleProps,
	SvgInteractiveStyleProps
> = {
	normal: svgStaticStyleMap,
	hover: {
		...svgInteractiveStyleMap,
		$fill: getInteractiveFill('hover'),
	},
	active: {
		...svgInteractiveStyleMap,
		$fill: getInteractiveFill('active'),
	},
	focus: svgInteractiveStyleMap,
	checked: svgInteractiveStyleMap,
	disabled: svgInteractiveStyleMap,
};

/**
 * Component to style SVG elements.
 *
 * The actual SVG should be supplied through the `as` property. It should be of type `SvgContent`.
 *
 * To goal of this separation is to allow a styled SVG component to be used with many different SVG images.
 *
 * Responsibilities:
 *
 * 1. Styling of SVG elements.
 * 2. Default SVG's to their cropped version.
 */
export const Svg = styled.svg.attrs<SvgComponentStyleProps>(
	({ cropped = true }) => ({
		cropped,
	})
)<SvgComponentStyleProps>`
	${(props) => {
		return css`
			display: block;
			${baseStyle};
			max-width: unset;
			${getCSSFromStyleProps(svgStyleMap, props)};
		`;
	}};
`;

export type SvgProps = StyledComponentPropsWithoutRef<typeof Svg>;
