import React from 'react';

import { isEmail, isInternalLink } from '@dop/shared/helpers/link';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { IconEmail } from '@dop/ui-icons/svg/email';
import { IconLinkExternal } from '@dop/ui-icons/svg/linkExternal';

import { StayWithLastWord } from '../typography/StayWithLastWord';

export type WithLinkTypeIconProps = {
	href: string | undefined;
	children: React.ReactNode;
};

/**
 * Show an link with it's according icon only when the href points to an external
 * resource or mailto.
 *
 * The text inside the link must be provided to this component so we can
 * avoid a line break between the last word and the icon.
 *
 * This is not the link itself, nor is it a TextBlock. This component
 * only adds the link icon.
 */
export const WithLinkTypeIcon = ({ href, children }: WithLinkTypeIconProps) => {
	if (href == null || isInternalLink(href)) return <>{children}</>;

	return (
		<StayWithLastWord
			element={
				<StayWithLastWord.Gap $gap="calc(var(--cap-height) * 0.3)">
					<Svg
						as={isEmail(href) ? IconEmail : IconLinkExternal}
						$display="inline-block"
						$translate="0 calc(var(--cap-height) * -0.7 + 10%)"
						$blockSize="calc(var(--cap-height) * 0.8)"
						$inlineSize={'auto'}
					/>
				</StayWithLastWord.Gap>
			}
		>
			{children}
		</StayWithLastWord>
	);
};
