import React from 'react';

import {
	AInline,
	AInlineProps,
} from '@dop/ui-primitives/interaction/InteractionInline';
import { isProtocolWhitelisted } from '@dop/shared/helpers/link';
import { DOPLink } from '@/uiComposites/interactive/DOPLink';

const LinkInline_ = (
	{ linkType, ...props }: AInlineProps & { linkType: string },
	ref: React.ForwardedRef<null>
): React.ReactElement | null => {
	if (props.href == null) return null;

	if (!isProtocolWhitelisted(props.href)) {
		return null;
	}

	return (
		<DOPLink
			href={props.href}
			data-link-type={linkType}
			legacyBehavior
			passHref
		>
			<AInline ref={ref} data-link-type={linkType} {...props} />
		</DOPLink>
	);
};

/**
 * If you need to use a styled link, you probably want to use LinkInlineDefault with an build-in external icon if link is external. Use this component if you need an unstyled link.
 */
export const LinkInline = React.forwardRef(LinkInline_);
export type LinkInlineProps = React.ComponentPropsWithoutRef<typeof LinkInline>;
