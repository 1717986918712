import React from 'react';
import { Property as CSSProperty } from 'csstype';
import styled from 'styled-components';

import { TextInline } from '@dop/ui-primitives/typography/TextInline';
import { getStringOrRootCapCSSValue } from '@dop/ui-primitives/base/commonStyleFunctions';

export type StayWithLastWordProps = {
	children: string | React.ReactNode;
	element: React.ReactElement;
};

/**
 * This component makes sure there is no linebreak
 * between a piece of text and an inline element.
 *
 * This is only possible when the `children` prop
 * is plain text. Otherwise the children and the
 * element are still displayed but line breaks may occure.
 *
 * `StayWithLastWord.Gap` can be used to add a gap between the text and the element.
 */
export const StayWithLastWord = ({
	children,
	element,
}: StayWithLastWordProps) => {
	if (typeof children !== 'string') {
		return (
			<>
				{children}
				{element}
			</>
		);
	}

	// First match group: match the entire string up to and including the last whitespace or new line.
	// Second match group: match the last word including any punctuation.
	const splitLastWord = children.trimEnd().match(/^(.*[\s\n])([^\s\n]+)$/);

	if (splitLastWord == null) {
		return (
			<>
				{children}
				{element}
			</>
		);
	}

	const [, text, lastWord] = splitLastWord;

	return (
		<>
			{text}
			<TextInline $display="inline-block" $textDecorationLine="inherit">
				{lastWord}
				{element}
			</TextInline>
		</>
	);
};

const StayWithLastWordElementGap = styled.span<{
	$gap: CSSProperty.MarginInlineStart | string;
}>`
	margin-inline-start: ${({ $gap }) => getStringOrRootCapCSSValue($gap)};
`;

StayWithLastWord.Gap = StayWithLastWordElementGap;
